import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Toast, { POSITION } from 'vue-toastification'

import { BootstrapVue } from 'bootstrap-vue'
import 'vue-toastification/dist/index.css'
import './assets/scss/app.scss'

// TODO : USE SERVICES WITH CLASSES !!!

const options = {
  position: POSITION.BOTTOM_RIGHT,
  maxToasts: 10,
  timeout: 3000
}

Vue.use(Toast, options)
Vue.use(BootstrapVue)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
