






import Vue from 'vue'
import axios from 'axios'

// If logged in => call api to check login
// change font !!!
export default Vue.extend({
  name: 'App',

  components: {},

  data: () => ({
    pepe: 'pp',
    urltest: 'https://discord.com/api/oauth2/authorize?client_id=765754752147128331&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fbind&response_type=code&scope=identify%20email%20guilds%20guilds.join'
  }),

  methods: {
    async logOut () {
      try {
        await axios.get('http://localhost:4200/auth/log-out', { withCredentials: true })
        this.$router.push('/')
        return true
      } catch (err) {
        console.log('ERROR:', err)
        return false
      }
    }
  }
})
