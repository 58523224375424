import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import axios from 'axios'

const API_BASE_URL = 'http://localhost:4200/'

const checkCustomer = async (): Promise<boolean> => {
  try {
    const { data } = await axios.get(API_BASE_URL + 'auth/customer', {
      withCredentials: true
    })

    if (data === true) return true
    else return false
  } catch (error) {
    return false
  }
}

const checkUser = async (): Promise<boolean> => {
  try {
    const { data } = await axios.get(API_BASE_URL + 'auth/user', {
      withCredentials: true
    })
    console.log(data)
    if (data === true) return true
    else return false
  } catch (error) {
    return false
  }
}

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: { requiresNoLogin: true }
  },
  {
    path: '/bind',
    name: 'Bind',
    component: () => import('@/views/Bind.vue'),
    meta: { requiresUser: true }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: { requiresCustomer: true }
  },
  {
    path: '/prod',
    name: 'Alpha Product',
    component: () => import('@/views/TestProd.vue'),
    meta: { requiresNoCustomer: true }
  },
  {
    path: '/success',
    name: 'Successful Purchase',
    component: () => import('@/views/Success.vue')
  },
  {
    path: '/failure',
    name: 'failed Purchase',
    component: () => import('@/views/FailedPurchase.vue')
  },
  { // redirect all other routes to
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) { return { x: 0, y: 0 } }
})

// TODO : make a REAL authentication system (checking info / backend implementation)
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresCustomer)) {       // check if route is needs auth
    await checkCustomer() ? next() : next('/bind')
  }
  if (to.matched.some(record => record.meta.requiresUser)) {
    console.log('we are requiring user')
    await checkUser() ? next() : next('/login')
  } else next()
})

export default router
